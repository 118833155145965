import { FirebaseApp, initializeApp } from 'firebase/app'
import { RemoteConfig, Value, getRemoteConfig, isSupported } from 'firebase/remote-config'
import { Database, getDatabase } from 'firebase/database'
import { Auth, getAuth } from 'firebase/auth'
import * as Sentry from '@sentry/react'

import { RemoteConfigKey, RemoteConfigState } from 'src/store/modules/remote-config'

import rcDefaults from '../constants/remote_config_defaults.json'
import { firebaseConfig } from '../constants/firebaseConfig'

export let firebaseApp: FirebaseApp
export let firebaseRemoteConfig: RemoteConfig
export let firebaseDatabase: Database
export let firebaseAuth: Auth

export const initFirebaseApp = async () => {
	try {
		firebaseApp = initializeApp(firebaseConfig)
		const isRemoteConfigSupported = await isSupported()

		if (isRemoteConfigSupported) {
			firebaseRemoteConfig = getRemoteConfig(firebaseApp)
			firebaseRemoteConfig.defaultConfig = rcDefaults
			firebaseRemoteConfig.settings.minimumFetchIntervalMillis =
				parseInt(import.meta.env.VITE_FIREBASE_CONFIG_INTERVALS as string) * 60000
		}
		firebaseDatabase = getDatabase(firebaseApp)
		firebaseAuth = getAuth(firebaseApp)
	} catch (error) {
		Sentry.captureException(error)
	}
}

export const convertRemoteConfigValues = (allParameters: Record<string, Value>) => {
	const config: Partial<RemoteConfigState['configs']> = {} // Using Partial to allow gradual filling of the object

	for (const key in allParameters) {
		const value = allParameters[key]
		switch (key) {
			case RemoteConfigKey.PrivacyPolicy:
				config[RemoteConfigKey.PrivacyPolicy] = value.asString()
				break
			case RemoteConfigKey.RevertInstanceEnabled:
				config[RemoteConfigKey.RevertInstanceEnabled] = value.asBoolean()
				break
			case RemoteConfigKey.SyncEnabled:
				config[RemoteConfigKey.SyncEnabled] = value.asBoolean()
				break
			case RemoteConfigKey.UploadUpdateEnabled:
				config[RemoteConfigKey.UploadUpdateEnabled] = value.asBoolean()
				break
			case RemoteConfigKey.cameraEnabled:
				config[RemoteConfigKey.cameraEnabled] = value.asBoolean()
				break
			case RemoteConfigKey.isDatabasesEnabled:
				config[RemoteConfigKey.isDatabasesEnabled] = value.asBoolean()
				break
			case RemoteConfigKey.enableHq:
				config[RemoteConfigKey.enableHq] = value.asBoolean()
				break
			case RemoteConfigKey.enableInbox:
				config[RemoteConfigKey.enableInbox] = value.asBoolean()
				break
			case RemoteConfigKey.bidsEnabled:
				config[RemoteConfigKey.bidsEnabled] = value.asBoolean()
				break
			case RemoteConfigKey.enableMultipleActions:
				config[RemoteConfigKey.enableMultipleActions] = value.asBoolean()
				break
		}
	}

	return config
}
